<template>
  <div>
    <v-card>
      <v-card-title> Relatorios </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete
              :items="getConstructionsSelect"
              outlined
              label="Obra(s)"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="report.constructions"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Data"
              outlined
              type="date"
              clearable
              v-model="report.loadS"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn color="success" x-large @click="loadReport"
              >Buscar Resumo</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- card da container dos outros cards -->
    <v-card class="mt-2" v-show="reportData.length">
      <v-card-title>Resumo do dia {{ dateStr }}</v-card-title>
      <v-card-subtitle>Obras: {{ reportData.length }}</v-card-subtitle>
      <v-card-text>
        <v-card
          class="d-flex justify-space-between flex-wrap mt-2"
          elevation="0"
        >
          <v-card
            v-for="resume in reportData"
            :key="resume.id"
            class="pa-2 ma-2"
            outlined
          >
            <v-card-title>{{ resume.constructionName }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <p class="font-weight-bold">Cargas:</p>
                </v-col>
                <v-col>
                  <p class="text-end">
                    <!-- {{ resume.orders.length }} -->
                    {{ resume.loaded }}
                  </p>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <!-- <v-row class="mt-1">
                <v-col>
                  <p class="font-weight-bold">Cargas:</p>
                </v-col>
                <v-col>
                  <p class="text-end">
                    {{ resume.loaded }}
                  </p>
                </v-col>
              </v-row>
              <v-divider></v-divider> -->
              <v-row class="mt-1">
                <v-col>
                  <p class="font-weight-bold">Descargas:</p>
                </v-col>
                <v-col>
                  <p class="text-end">
                    {{ resume.unloaded }}
                  </p>
                </v-col>
              </v-row>
              <!-- card do item -->
              <v-card
                v-for="itemsLo in resume.items"
                :key="itemsLo.id"
                class="ma-1 mb-0"
              >
                <v-card-title>
                  {{ itemsLo.itemName }}
                </v-card-title>
                <v-card-subtitle>
                  <v-row no-gutters>
                    <v-col>
                      Solicitado: {{ getSendedAmount(itemsLo.orders) }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col> Saldo: {{ (getSendedAmount(itemsLo.orders) - itemsLo.quantityLoaded || 0 ).toFixed(2)}} </v-col>
                  </v-row>
                  <!-- Enviado: {{ getBiggerAmount(itemsLo.orders) }} -->
                </v-card-subtitle>
                <v-card-text>
                  <!-- <v-row class="mt-1">
                    <v-col>
                      <p class="font-weight-bold">Cargas:</p>
                    </v-col>
                    <v-col>
                      <p class="text-end">
                        {{ itemsLo.loaded }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider> -->
                  <!-- <v-row class="mt-1">
                    <v-col>
                      <p class="font-weight-bold">Descargas:</p>
                    </v-col>
                    <v-col>
                      <p class="text-end">
                        {{ itemsLo.unloaded }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider> -->
                  <v-row class="mt-1">
                    <v-col>
                      <p class="font-weight-bold">Carregado:</p>
                    </v-col>
                    <v-col>
                      <p class="text-end">
                        {{
                          itemsLo.quantityLoaded
                            ? itemsLo.quantityLoaded.toFixed(2)
                            : 0
                        }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="mt-1">
                    <v-col>
                      <p class="font-weight-bold">Descarregado:</p>
                    </v-col>
                    <v-col>
                      <p class="text-end">
                        {{
                          itemsLo.quantityUnloaded
                            ? itemsLo.quantityUnloaded.toFixed(2)
                            : 0
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ResumoDoDia",
  components: {},
  data: () => ({
    report: {
      dirI: "",
      constructions: [],
      loadS: "",
      loadE: "",
    },
    reportData: [],
    dateStr: "",
    items: [],
  }),
  methods: {
    loadReport() {
      let rep = {
        loadS: `${this.report.loadS} 00:00:00`,
        loadE: `${this.report.loadS} 23:59:59`,
        constructions: this.report.constructions,
      };
      this.dateStr = new Date(`${this.report.loadS} 23:59:59`).toLocaleDateString();
      this.$store.dispatch("getReportBOtoUser", rep);
    },
    startSelectors() {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
    },
    getItemName(itemId) {
      let item = this.getOrderItems.find((e) => e.id == itemId);
      return item ? `${item.name} - ${item.unity}(s)` : "";
    },
    getBiggerAmount(list) {
      let amounts = list.map((e) => parseFloat(e.amount));
      let max = amounts.reduce(function (a, b) {
        return Math.max(a, b);
      });
      return Number(max).toFixed(2);
    },
    getSendedAmount(list) {
      let amounts = list.map((e) =>
        e.createUserId != "0" ? parseFloat(e.amount) : 0
      );
      let value = amounts.reduce(function (a, b) {
        return a + b;
      });
      return Number(value).toFixed(2);
    },
  },
  computed: {
    ...mapGetters([
      "getBOReport",
      "getOrderItems",
      "getConstructionNameById",
      "getConstructionsSelect",
    ]),
  },
  watch: {
    getBOReport() {
      let constructionsIds = [
        ...new Set(this.getBOReport.map((e) => e.order.constructionId)),
      ];
      let data = [];
      // separando por obras
      constructionsIds.forEach((e) => {
        data.push({
          constructionId: e,
          constructionName: this.getConstructionNameById(e),
          orders: this.getBOReport.filter((f) => f.order.constructionId == e),
          sended: this.getBOReport.filter(
            (f) => f.order.constructionId == e && f.createUserId != "0"
          ).length,
          loaded: this.getBOReport.filter(
            (f) => f.order.constructionId == e && f.loadedDate
          ).length,
          unloaded: this.getBOReport.filter(
            (f) => f.order.constructionId == e && f.finishedDate
          ).length,
          // quantityLoaded: this.getBOReport
          //   .filter((f) => f.order.constructionId == e && f.loadedDate)
          //   .reduce((a, b) => a + b.colectedAmount, 0),
          // quantityUnloaded: this.getBOReport
          //   .filter((f) => f.order.constructionId == e && f.finishedDate)
          //   .reduce((a, b) => a + b.colectedAmount, 0),
        });
      });
      // separando por items
      data.forEach((e) => {
        let itemsIds = [...new Set(e.orders.map((e) => e.itemId))];
        let items = [];
        itemsIds.forEach((f) => {
          items.push({
            itemId: f,
            itemName: this.getItemName(f),
            orders: e.orders.filter((g) => g.itemId == f),
            loaded: e.orders.filter((g) => g.itemId == f && g.loadedDate)
              .length,
            unloaded: e.orders.filter((g) => g.itemId == f && g.finishedDate)
              .length,
            quantityLoaded: e.orders
              .filter((g) => g.itemId == f && g.loadedDate)
              .reduce((a, b) => a + b.colectedAmount, 0),
            quantityUnloaded: e.orders
              .filter((g) => g.itemId == f && g.finishedDate)
              .reduce((a, b) => a + b.colectedAmount, 0),
          });
        });
        e.items = items;
      });
      this.reportData = data;
      // console.log(data);
    },
    // buscar items diferentes dentro de um array
  },
  mounted() {
    this.$store.dispatch("loadConstructions");
    this.$store.dispatch("loadOrdersItems");
    this.report.loadS = this.startSelectors();
    this.report.loadE = this.startSelectors();
  },
};
</script>

<style>
</style>